import { Injectable } from '@angular/core';
import { ItemListe } from 'src/app/data/item-liste';
import {
  AusruestungTyp,
  ItemId,
  ItemSeltenheit,
  ItemTyp,
} from 'src/app/services/items/item-enums';
import { ItemReferenz } from 'src/app/services/items/item-referenz';
import { DebugLuck } from 'src/app/services/debug/debug-luck';
import type { Item } from 'src/app/services/items/item';
import type { Spieler } from 'src/app/services/spieler/spieler';

@Injectable({
  providedIn: 'root',
})
export class SchmiedeService {
  private readonly ruestungen: AusruestungTyp[] = [
    AusruestungTyp.Helm,
    AusruestungTyp.Ruestung,
    AusruestungTyp.Handschuhe,
    AusruestungTyp.Schuhe,
    AusruestungTyp.HandRechts,
  ];
  private readonly schmuck: AusruestungTyp[] = [
    AusruestungTyp.Halskette,
    AusruestungTyp.Guertel,
    AusruestungTyp.Ring,
  ];

  constructor() {}

  public getCraftMaterialIds(spieler: Spieler, ruestungen: boolean): string[] {
    const karte: number = spieler.kartenNummer;

    if (ruestungen) {
      return ['Craft1Lv' + karte, 'Craft2Lv' + karte];
    }

    return ['Craft3Lv' + karte, 'Craft4Lv' + karte];
  }

  public verfuegbareCrafts(spieler: Spieler): ItemReferenz[] {
    const retIDs: AusruestungTyp[] = [];

    if (
      this.getCraftMaterialIds(spieler, true).every((id) => spieler.hasItem(id))
    ) {
      retIDs.push(...this.ruestungen);
    }

    if (
      this.getCraftMaterialIds(spieler, false).every((id) =>
        spieler.hasItem(id)
      )
    ) {
      retIDs.push(...this.schmuck);
    }

    return retIDs.map((val) =>
      ItemListe.getEquipmentByLevelAndType(
        spieler.kartenNummer,
        val
      ).getReferenz()
    );
  }

  public getCraftMaterialDisplay(
    spieler: Spieler,
    typ: AusruestungTyp
  ): (ItemReferenz | undefined)[] {
    let materialIds: string[];

    if (this.ruestungen.includes(typ)) {
      materialIds = this.getCraftMaterialIds(spieler, true);
    } else if (this.schmuck.includes(typ)) {
      materialIds = this.getCraftMaterialIds(spieler, false);
    } else {
      return [undefined, undefined];
    }

    return materialIds.map((id) =>
      this.makeDisplayItem(spieler.getItemsById(id))
    );
  }

  public getAllCraftMaterialDisplay(spieler: Spieler): ItemReferenz[] {
    return [
      ...this.getCraftMaterialIds(spieler, true),
      ...this.getCraftMaterialIds(spieler, false),
    ]
      .map((id) => this.makeDisplayItem(spieler.getItemsById(id)))
      .filter((val): val is ItemReferenz => val != null);
  }

  public craftItem(spieler: Spieler, typ: AusruestungTyp): boolean {
    let materialIds: string[];

    if (this.ruestungen.includes(typ)) {
      materialIds = this.getCraftMaterialIds(spieler, true);
    } else if (this.schmuck.includes(typ)) {
      materialIds = this.getCraftMaterialIds(spieler, false);
    } else {
      return false;
    }

    const materialRefs: (ItemReferenz | undefined)[] = materialIds.map((id) =>
      spieler.getItemsById(id).pop()
    );

    if (materialRefs.some((item) => item == null)) {
      return false;
    }

    materialRefs.forEach((ref) => spieler.removeOneFrom(ref));
    spieler.addItemToInventory(
      ItemListe.getEquipmentByLevelAndType(spieler.kartenNummer, typ)
    );

    return true;
  }

  public getUpgradeMaterials(
    spieler: Spieler,
    equipment: Item
  ): ItemReferenz[] {
    let upId: string = 'Up';

    if (this.ruestungen.includes(equipment.ausruestungTyp)) {
      upId += '1';
    } else if (this.schmuck.includes(equipment.ausruestungTyp)) {
      upId += '2';
    } else {
      return [];
    }

    return spieler.getItemsById(upId);
  }

  public getUpgradeMaterialDisplay(
    spieler: Spieler,
    equipmentRef: ItemReferenz,
    summiert: boolean = true
  ): ItemReferenz | undefined {
    const equipment: Item = equipmentRef.getPublishedItem();

    if (
      !(
        spieler.ausruestung.includes(equipmentRef) ||
        spieler.inventar.includes(equipmentRef)
      ) ||
      equipment.ausruestungTyp == AusruestungTyp.Kein ||
      equipment.typ != ItemTyp.Equipment ||
      equipment.equipmentStats == null ||
      equipment.equipmentStats.upgradeLevel >= 9
    ) {
      return undefined;
    }

    const upItems: ItemReferenz[] = this.getUpgradeMaterials(
      spieler,
      equipment
    );

    if (upItems.length <= 0) {
      return undefined;
    }

    if (!summiert) {
      return upItems.pop();
    }

    return this.makeDisplayItem(upItems);
  }

  public upgradeEquipment(
    spieler: Spieler,
    equipmentRef: ItemReferenz,
    mitSchmiedehammer: boolean
  ): boolean {
    const upItem: ItemReferenz | undefined = this.getUpgradeMaterialDisplay(
      spieler,
      equipmentRef,
      false
    );

    const equipment: Item = equipmentRef.createItem();

    if (
      upItem == null ||
      equipment.equipmentStats == null ||
      !spieler.inventar.includes(upItem) ||
      !(
        spieler.ausruestung.includes(equipmentRef) ||
        spieler.inventar.includes(equipmentRef)
      )
    ) {
      return false;
    }

    const mult: number = spieler.removeOneOfType(ItemId.Schmiedehammer) ? 2 : 1;
    const baseChance: number = 100 - (equipmentRef.upgradeLevel ?? 0) * 10;
    const roll: number = DebugLuck.getLuck(100, Math.random() * 100, 0);

    spieler.removeOneFrom(upItem);

    const success: boolean = roll <= baseChance * mult;

    if (success) {
      if (equipmentRef.upgradeLevel == null) {
        equipmentRef.upgradeLevel = 0;
      }
      equipmentRef.upgradeLevel++;
      equipmentRef.publishItem();
      spieler.resortInventory();
    }

    return success;
  }

  public kannNeuschmieden(
    spieler: Spieler,
    equipmentRef: ItemReferenz,
    opferRef?: ItemReferenz,
    mitSchmiedehammer: boolean = true
  ): boolean {
    const equipment: Item = equipmentRef.createItem(true);

    if (
      equipment.ausruestungTyp == AusruestungTyp.Kein ||
      equipment.seltenheit == ItemSeltenheit.Legendaer ||
      equipment.seltenheit == ItemSeltenheit.Mythisch ||
      equipment.stackSize != 1 ||
      equipment.anzahl != 1 ||
      equipment.typ != ItemTyp.Equipment ||
      !(
        spieler.ausruestung.includes(equipmentRef) ||
        spieler.inventar.includes(equipmentRef)
      ) ||
      !(
        this.ruestungen.includes(equipment.ausruestungTyp) ||
        this.schmuck.includes(equipment.ausruestungTyp)
      )
    ) {
      return false;
    }

    if (
      equipment.seltenheit == ItemSeltenheit.Episch &&
      (!mitSchmiedehammer || !spieler.hasItem(ItemId.Schmiedehammer))
    ) {
      return false;
    }

    if (opferRef == null) {
      return (
        spieler.inventar.filter((item) =>
          this.kannNeuschmieden(spieler, equipmentRef, item, mitSchmiedehammer)
        ).length > 0
      );
    }

    const opfer: Item = opferRef.createItem(true);

    if (
      equipmentRef === opferRef ||
      equipment.ausruestungTyp != opfer.ausruestungTyp ||
      equipment.seltenheit != opfer.seltenheit ||
      equipment.id != opfer.id ||
      equipment.name != opfer.name ||
      equipment.kartenNummer != opfer.kartenNummer ||
      equipment.stackSize != opfer.stackSize ||
      equipment.anzahl != opfer.anzahl ||
      equipment.typ != opfer.typ ||
      !spieler.inventar.includes(opferRef)
    ) {
      return false;
    }

    return true;
  }

  public neuschmieden(
    spieler: Spieler,
    equipment: ItemReferenz,
    opfer: ItemReferenz | undefined,
    mitSchmiedehammer: boolean
  ): boolean {
    if (
      opfer == null ||
      !this.kannNeuschmieden(spieler, equipment, opfer, mitSchmiedehammer)
    ) {
      return false;
    }

    const rewards: ItemSeltenheit[] = [
      ItemSeltenheit.Gewoehnlich, // heißt hier kaputt
      ItemSeltenheit.Ungewoehnlich,
      ItemSeltenheit.Selten,
      ItemSeltenheit.Episch,
      ItemSeltenheit.Legendaer,
    ];

    const hammer: ItemReferenz | undefined = mitSchmiedehammer
      ? spieler.getItemsById(ItemId.Schmiedehammer).pop()
      : undefined;

    // Standard für 2 Epische ohne Hammer (alles kaputt)
    let chances: number[] = [100, 100, 100, 100, 100];

    switch (equipment.seltenheit ?? ItemSeltenheit.Gewoehnlich) {
      case ItemSeltenheit.Gewoehnlich:
        if (hammer != null) {
          chances = [40, 70, 94, 99, 100];
        } else {
          chances = [80, 93, 99, 100, 100];
        }
        break;
      case ItemSeltenheit.Ungewoehnlich:
        if (hammer != null) {
          chances = [50, 50, 90, 98, 100];
        } else {
          chances = [80, 80, 97, 100, 100];
        }
        break;
      case ItemSeltenheit.Selten:
        if (hammer != null) {
          chances = [60, 60, 60, 95, 100];
        } else {
          chances = [90, 90, 90, 100, 100];
        }
        break;
      case ItemSeltenheit.Episch:
        if (hammer != null) {
          chances = [70, 70, 70, 70, 100];
        } else {
          chances = [100, 100, 100, 100, 100];
        }
        break;
      default:
        console.log('Ungewöhnliches Equipment', equipment);
        return false;
    }

    const neueSeltenheit: ItemSeltenheit = ItemListe.roll(
      rewards,
      chances,
      100,
      true
    );

    spieler.removeOneFrom(opfer);
    spieler.removeOneFrom(hammer);

    // Wenn Opfer nicht einfach nur kaputt gegangen ist
    if (neueSeltenheit != ItemSeltenheit.Gewoehnlich) {
      equipment.seltenheit = neueSeltenheit;
      equipment.publishItem();
      spieler.resortInventory();
      return true;
    }

    return false;
  }

  private makeDisplayItem(input: ItemReferenz[]): ItemReferenz | undefined {
    if (input.length <= 0) {
      return undefined;
    }

    const anzahl: number = input.reduce(
      (val, item) => val + (item.anzahl ?? 1),
      0
    );

    if (anzahl <= 0) {
      return undefined;
    }

    return new ItemReferenz({
      id: input[0].id,
      anzahl: anzahl,
    });
  }

  public getMainPet(spieler: Spieler | undefined): ItemReferenz | undefined {
    return spieler?.ausruestung.find((item) => item.id == ItemId.Pet);
  }

  public getOtherPets(spieler: Spieler | undefined): ItemReferenz[] {
    return spieler?.getItemsById(ItemId.Pet) ?? [];
  }

  public fusionierePet(
    spieler: Spieler | undefined,
    opferPet: ItemReferenz | undefined
  ): boolean {
    if (spieler == null || opferPet == null) {
      return false;
    }

    const mainPet: ItemReferenz | undefined = this.getMainPet(spieler);
    if (mainPet == null) {
      return false;
    }

    const additiveBonus: number = Math.max(
      mainPet.additiveBonus ?? 0,
      opferPet.additiveBonus ?? 0
    );
    const level: number = (mainPet.level ?? 1) + (opferPet.level ?? 1);
    const sterne: number = (mainPet.sterne ?? 0) + (opferPet.sterne ?? 0) + 1;

    if (!spieler.removeOneFrom(opferPet)) {
      return false;
    }

    mainPet.additiveBonus = additiveBonus;
    mainPet.level = level;
    mainPet.sterne = sterne;

    mainPet.publishItem();
    return true;
  }
}
